import React from "react"

const Timeline = () => (
  <div className="how-it-works-timeline">
    <div className="timeline-item not-last">
      <div className="timeline-text">
        <h4>Wait</h4>
        <p>Until just after you’ve finished with a customer.</p>
      </div>
      <div className="timeline-point-container">
        <div className="timeline-point" />
      </div>
    </div>
    <div className="timeline-item">
      <div className="timeline-text">
        <h4>Hit Send</h4>
        <p>
          Open our app on desktop, mobile or tablet, enter your customers phone
          number.
        </p>
      </div>
      <div className="timeline-point-container">
        <div className="timeline-point" />
      </div>
    </div>
  </div>
)

export default Timeline
