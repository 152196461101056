import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Timeline from "../components/how-it-works-timeline.js"
import invitebox from "../images/box.svg"
import CtaButton from "../components/CtaButton"
import FeatureThreeImage from "../images/how-it-works-feature-three.png"
import FeatureFourImage from "../images/how-it-works-feature-four.png"

const HowItWorks = () => (
  <Layout>
    <Helmet>
      <title>How It Works | InHero</title>
    </Helmet>
    <div className="container how-it-works-container">
      <section className="how-it-works-hero">
        <h3>Build a great reputation in just 2 steps</h3>
      </section>
      <section className="how-it-works-feature-one">
        <Timeline />
        <img src={invitebox} alt="" />
      </section>
      <section className="how-it-works-feature-two">
        <div className="how-it-works-feature-two-title">
          <h3>Your job is done.</h3>
          <h3>Now it’s our turn.</h3>
        </div>
        <h4>
          These 2 steps will get you more reviews, win new customers and boost
          your local SEO
        </h4>
        <CtaButton />
      </section>
      <section className="how-it-works-feature-three container">
        <div className="how-it-works-feature-three-text">
          <h4>Customer gets SMS</h4>
          <p>
            The customer clicks on the link and is taken through a streamlined
            review process.
          </p>
        </div>
        <img src={FeatureThreeImage} alt="" />
      </section>
      <section className="how-it-works-feature-four container">
        <img src={FeatureFourImage} alt="" />
        <div className="how-it-works-feature-four-text">
          <h4>Customer selects review site</h4>
          <p>
            Customer chooses where to leave a review according to the order you
            prefer.
          </p>
        </div>
      </section>
      <section className="how-it-works-feature-five">
        <h4>You’ve got yourself another great review!</h4>
        <CtaButton />
      </section>
    </div>
  </Layout>
)

export default HowItWorks
